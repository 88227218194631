import React from "react";
import Button from "../button";

function Buttons(props) {
  const {
    onClickRoute,
    drivingState,
    walkingState,
    onClickMyLocation,
    myLocationState,
    onClickDestination,
    loading,
  } = props;
  return (
    <div className="row">
      <div className="col-md">
        <div className="action-buttons">
          <Button
            key="driving-route"
            title={"Putanja vozilom".translate()}
            clickAction={() => onClickRoute("driving")}
            icon={<i className="bi bi-car-front-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : drivingState}
          />
          <Button
            key="walking-route"
            title={"Putanja peške".translate()}
            clickAction={onClickRoute}
            icon={<i className="bi bi-compass-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : walkingState}
          />
          <Button
            key="my-location"
            title={"Moja lokacija".translate()}
            clickAction={onClickMyLocation}
            icon={<i className="bi bi-geo-alt-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : myLocationState}
          />
          <Button
            key="destination"
            title={"Destinacija".translate()}
            clickAction={onClickDestination}
            icon={<i className="bi bi-geo-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : null}
          />
        </div>
      </div>
    </div>
  );
}

export default Buttons;
