import React from "react";

function Button(props) {
  const { title, clickAction, icon, badgeValue, currentState } = props;
  return (
    <button
      disabled={currentState === "disabled" ? true : false}
      title={title}
      className={
        currentState === "disabled"
          ? "sort-button button-disabled"
          : currentState === "active"
          ? "sort-button button-active"
          : "sort-button"
      }
      onClick={clickAction}
    >
      {icon}
      {badgeValue > 0 ? (
        <span className="position-absolute top-0 start-100 badge-custom badge rounded-pill bg-danger">
          {badgeValue}
        </span>
      ) : null}
    </button>
  );
}

export default Button;
