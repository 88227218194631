import React from "react";
import Image from "../image";
import { Link } from "react-router-dom";
import Placeholder from "react-bootstrap/Placeholder";

function LocationItem(props) {
  const { lang, loading, location, imageSize } = props;
  return (
    <>
      {loading && (
        <div className="col-xl-12 search-location-wrap">
          <div className="search-location-image d-block">
            <Placeholder animation="glow">
              <Placeholder style={{ width: "100%", height: "100%" }} />
            </Placeholder>
          </div>
          <div className="search-location-content w-100">
            <div className="search-location-name">
              <Placeholder animation="glow">
                <Placeholder xs={8} size="sm" />
              </Placeholder>
            </div>
            <div className="search-location-text">
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
                <Placeholder xs={8} size="sm" />
              </Placeholder>
            </div>
          </div>
        </div>
      )}
      {!loading && (
        <Link
          className="col-xl-12 search-location-wrap"
          to={
            "/" +
            location.stub_1 +
            "/" +
            location.stub_2 +
            "/" +
            location.stub_3
          }
        >
          <div className="search-location-image">
            <Image
              imageSrc={
                imageSize === "m"
                  ? location.path + "medium/" + location.filename
                  : location.path + "small/" + location.filename
              }
            />
          </div>
          <div className="search-location-content">
            <div className="search-location-name">
              {location["name_" + lang]}
            </div>
            <div className="search-location-text">
              {location["text_" + lang].split(" ").slice(0, 10).join(" ") +
                "..."}
            </div>
          </div>
        </Link>
      )}
    </>
  );
}

export default LocationItem;
