import React from "react";
import Image from "../image";
import { Link, useLocation } from "react-router-dom";
import Placeholder from "react-bootstrap/Placeholder";

function SingleObjectContact(props) {
  const location = useLocation();
  const { contact, lang, loading } = props;

  if (loading) {
    return (
      <div className="col-md-6 g-3">
        <div className="group-item">
          <div className="row ms-0 me-0 w-100">
            <div className="col ps-0 pe-0">
              <div className="group-item-inner">
                <div className="group-item-inner-text">
                  <div className="group-item-inner-text-title">
                    {loading && (
                      <Placeholder animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    )}
                  </div>
                  <div className="group-item-inner-text-text">
                    {loading && (
                      <Placeholder animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    )}
                  </div>
                </div>
                <div className="group-item-inner-image">
                  {loading && <div style={{ width: "41px", height: "41px" }} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!loading && contact) {
    switch (contact.type) {
      case "1": //Adresa
        return (
          <Link to={location.pathname + "/map"} className="col-md-6 g-3">
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        );
        break;
      case "2": //Telefon
        return (
          <a href={"tel:" + contact.contact} className="col-md-6 g-3">
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "3": //Fax
        return (
          <a href={"tel:" + contact.contact} className="col-md-6 g-3">
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "4": //Mejl
        return (
          <a href={"mailto:" + contact.contact} className="col-md-6 g-3">
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "5": //Web
        return (
          <a
            href={"https://" + contact.contact}
            target="_blank"
            className="col-md-6 g-3"
          >
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "6": //Facebook
        return (
          <a
            href={"https://" + contact.contact}
            target="_blank"
            className="col-md-6 g-3"
          >
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "7": //Twitter
        return (
          <a
            href={"https://" + contact.contact}
            target="_blank"
            className="col-md-6 g-3"
          >
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "8": //Skype
        return (
          <a href={"skype:" + contact.contact} className="col-md-6 g-3">
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "9": //Viber
        return (
          <a href={"tel:" + contact.contact} className="col-md-6 g-3">
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
      case "10": //Instagram
        return (
          <a
            href={"https://www.instagram.com/" + contact.contact}
            target="_blank"
            className="col-md-6 g-3"
          >
            <div className="group-item">
              <div className="row ms-0 me-0 w-100">
                <div className="col ps-0 pe-0">
                  <div className="group-item-inner">
                    <div className="group-item-inner-text">
                      <div className="group-item-inner-text-title">
                        {contact["text_" + lang]}
                      </div>
                      <div className="group-item-inner-text-text">
                        {contact.contact}
                      </div>
                    </div>
                    <div className="group-item-inner-image">
                      <Image
                        imageUi={true}
                        imageSrc={contact.type_image}
                        imageAlt={contact["text_" + lang]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
        break;
    }
  }
}

export default SingleObjectContact;
