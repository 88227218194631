import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function RatingModal(props) {
  const { show, onCloseRate, onSubmitRate, ratingLoading, ratingError } = props;
  const [starOne, setStarOne] = useState(false);
  const [starTwo, setStarTwo] = useState(false);
  const [starThree, setStarThree] = useState(false);
  const [starFour, setStarFour] = useState(false);
  const [starFive, setStarFive] = useState(false);

  const calculateRating = () => {
    let rating = 0;
    if (starOne) rating = "1";
    if (starTwo) rating = "2";
    if (starThree) rating = "3";
    if (starFour) rating = "4";
    if (starFive) rating = "5";
    onSubmitRate(rating);
  };

  const resetRating = () => {
    setStarOne(false);
    setStarTwo(false);
    setStarThree(false);
    setStarFour(false);
    setStarFive(false);
  };

  const closeRating = () => {
    onCloseRate();
    resetRating();
  };

  return (
    <Modal
      show={show}
      onHide={closeRating}
      backdrop="static"
      keyboard={false}
      centered
      onEnter={resetRating}
    >
      <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">
          <i className="bi bi-star-fill icon-primary"></i>
          {"Oceni".translate()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="stars-holder">
          <div
            className="single-star"
            onClick={() => {
              setStarOne(true);
              setStarTwo(false);
              setStarThree(false);
              setStarFour(false);
              setStarFive(false);
            }}
          >
            {starOne ? (
              <i className="bi bi-star-fill"></i>
            ) : (
              <i className="bi bi-star"></i>
            )}
          </div>
          <div
            className="single-star"
            onClick={() => {
              setStarOne(true);
              setStarTwo(true);
              setStarThree(false);
              setStarFour(false);
              setStarFive(false);
            }}
          >
            {starTwo ? (
              <i className="bi bi-star-fill"></i>
            ) : (
              <i className="bi bi-star"></i>
            )}
          </div>
          <div
            className="single-star"
            onClick={() => {
              setStarOne(true);
              setStarTwo(true);
              setStarThree(true);
              setStarFour(false);
              setStarFive(false);
            }}
          >
            {starThree ? (
              <i className="bi bi-star-fill"></i>
            ) : (
              <i className="bi bi-star"></i>
            )}
          </div>
          <div
            className="single-star"
            onClick={() => {
              setStarOne(true);
              setStarTwo(true);
              setStarThree(true);
              setStarFour(true);
              setStarFive(false);
            }}
          >
            {starFour ? (
              <i className="bi bi-star-fill"></i>
            ) : (
              <i className="bi bi-star"></i>
            )}
          </div>
          <div
            className="single-star"
            onClick={() => {
              setStarOne(true);
              setStarTwo(true);
              setStarThree(true);
              setStarFour(true);
              setStarFive(true);
            }}
          >
            {starFive ? (
              <i className="bi bi-star-fill"></i>
            ) : (
              <i className="bi bi-star"></i>
            )}
          </div>
        </div>
        {ratingError && (
          <div className="error-form-text mt-3">{ratingError}</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={calculateRating}>
          {"Pošalji".translate()}&nbsp;
          {ratingLoading ? <i className="fa fa-spinner fa-spin"></i> : null}
        </Button>
        <Button variant="danger" onClick={closeRating}>
          {"Zatvori".translate()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RatingModal;
