import React, { useState, useEffect } from "react";
import SubHeader from "../subHeader";
import LocationItem from "./locationItem";

function MostLikesComments(props) {
  const { lang, showSubheader } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_most_likes_comments.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((location) => {
        setData(location);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="col-xl-6">
        {showSubheader && (
          <SubHeader
            title={"Najviše sviđanja".translate()}
            loading={loading}
            icon={<i className="bi bi-hand-thumbs-up-fill icon-primary"></i>}
          />
        )}
        <div className="row g-0 px-2 pt-1 pb-3 mb-3">
          {loading && <LocationItem loading={loading} />}
          {!loading && data && (
            <LocationItem loading={loading} location={data.likes} lang={lang} />
          )}
        </div>
      </div>
      <div className="col-xl-6">
        {showSubheader && (
          <SubHeader
            title={"Najviše komentara".translate()}
            loading={loading}
            icon={<i className="bi bi-chat-left-text-fill icon-primary"></i>}
          />
        )}
        <div className="row g-0 px-2 pt-1 pb-3 mb-3">
          {loading && <LocationItem loading={loading} />}
          {!loading && data && (
            <LocationItem
              loading={loading}
              location={data.comments}
              lang={lang}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MostLikesComments;
