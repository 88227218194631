import React from "react";
import SeoTags from "../components/seo";
import { useNavigate } from "react-router-dom";
function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <SeoTags
        title="404 - Stranica nije pronađena"
        description="Greška! Stranica koju tražite nije pronađena."
      />
      <main className="container-fluid p-0">
        <div className="container-md">
          <div className="row">
            <div className="col-lg">
              <div className="m-5 text-center">
                <h1>404</h1>
                <div className="mb-3">
                  Greška! Stranica koju tražite nije pronađena.
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Idi na naslovnu stranu
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default NotFound;
