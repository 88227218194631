import React from "react";
import { Helmet } from "react-helmet-async";
function SeoTags(props) {
  const { title, description, path, type, image } = props;
  let displayTitle;
  let displayDescription;
  let displayType;
  let displayImage;
  let displayPath;
  title
    ? (displayTitle = title + " | " + process.env.REACT_APP_NAME)
    : (displayTitle =
        process.env.REACT_APP_PREFIX + " | " + process.env.REACT_APP_NAME);
  description
    ? (displayDescription = description)
    : (displayDescription = process.env.REACT_APP_DESCRIPTION);
  type ? (displayType = type) : (displayType = "website");
  path
    ? (displayPath = process.env.REACT_APP_FRONTEND_ROOT + path)
    : (displayPath = process.env.REACT_APP_FRONTEND_ROOT);

  let displayLocale = process.env.REACT_APP_DEFAULT_LOCALE;
  image
    ? (displayImage = image)
    : (displayImage = process.env.REACT_APP_MAIN_IMAGE);

  return (
    <Helmet>
      <title>{displayTitle}</title>
      <meta name="title" content={displayTitle} />
      <meta name="description" content={displayDescription} />

      <meta property="og:type" content={displayType} />
      <meta property="og:locale" content={displayLocale} />
      <meta property="og:url" content={displayPath} />
      <meta property="og:title" content={displayTitle} />
      <meta property="og:description" content={displayDescription} />
      <meta property="og:image" content={displayImage} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={displayPath} />
      <meta property="twitter:title" content={displayTitle} />
      <meta property="twitter:description" content={displayDescription} />
      <meta property="twitter:image" content={displayImage} />
    </Helmet>
  );
}

export default SeoTags;
