import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
function CustomTooltip(props) {
  const { id, placement, text, trigger } = props;
  return (
    <OverlayTrigger
      key={id}
      placement={placement}
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {trigger}
    </OverlayTrigger>
  );
}

export default CustomTooltip;
