import React, { useState, useEffect } from "react";
import Group from "../components/home/group";
import SubHeader from "../components/subHeader";
import Header from "../components/header";
import PromoApp from "../components/promoApp";
import SidebarLatestEvents from "../components/sidebarLatestEvents";
import SidebarLatestNews from "../components/sidebarLatestNews";
import SeoTags from "../components/seo";

function Search(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { lang, locale } = props;

  useEffect(() => {
    const requestOptionsSearch = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        origin: "web",
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_groups.php",
      requestOptionsSearch
    )
      .then((response) => response.json())
      .then((objects) => {
        setData(objects);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function handleToggleSubmenu(index) {
    let newData = [];
    data.map((group, i) => {
      let currentState = group.subgroup_visible;
      i === index
        ? (group.subgroup_visible = !currentState)
        : (group.subgroup_visible = false);
      newData[i] = group;
    });
    setData(newData);
  }

  return (
    <>
      <SeoTags />
      <main className="container-fluid p-0">
        <div className="container-md mt-5">
          <div className="row">
            <div className="col-lg-8 mb-3">
              <SubHeader
                title={"Lokacije".translate()}
                linkTo="/lokacije"
                loading={loading}
                icon={<i className="bi bi-geo-alt icon-primary"></i>}
              />
              <div className="row g-0 px-2 pt-1 pb-3">
                {loading && (
                  <>
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                  </>
                )}
                {!loading &&
                  data.map((group, index) => (
                    <Group
                      key={group.group_id}
                      onToggleSubmenu={handleToggleSubmenu}
                      index={index}
                      group={group}
                      lang={lang}
                      loading={loading}
                    />
                  ))}
              </div>
            </div>
            <div className="col-lg-4 search-sidebar">
              <SidebarLatestNews
                lang={lang}
                locale={locale}
                showSubheader={true}
                newsSingle="0"
              />
            </div>
          </div>
        </div>
        <PromoApp />
        <div className="container-md">
          <div className="row my-3">
            <div className="col-lg-6">
              <SidebarLatestEvents
                lang={lang}
                locale={locale}
                category="dogadjaji"
                showSubheader={true}
              />
            </div>
            <div className="col-lg-6">
              <SidebarLatestEvents
                lang={lang}
                locale={locale}
                category="akcije-i-promocije"
                showSubheader={true}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Search;
