import React from "react";

function Rating(props) {
  const { rating } = props;
  return (
    <div className="object-list-item-rating">
      <div className="object-list-item-rating-star">
        {rating > 0 && rating < 0.5 ? (
          <i className="bi bi-star-half"></i>
        ) : rating >= 0.5 ? (
          <i className="bi bi-star-fill"></i>
        ) : (
          <i className="bi bi-star"></i>
        )}
      </div>
      <div className="object-list-item-rating-star">
        {rating > 1 && rating < 1.5 ? (
          <i className="bi bi-star-half"></i>
        ) : rating >= 1.5 ? (
          <i className="bi bi-star-fill"></i>
        ) : (
          <i className="bi bi-star"></i>
        )}
      </div>
      <div className="object-list-item-rating-star">
        {rating > 2 && rating < 2.5 ? (
          <i className="bi bi-star-half"></i>
        ) : rating >= 2.5 ? (
          <i className="bi bi-star-fill"></i>
        ) : (
          <i className="bi bi-star"></i>
        )}
      </div>
      <div className="object-list-item-rating-star">
        {rating > 3 && rating < 3.5 ? (
          <i className="bi bi-star-half"></i>
        ) : rating >= 3.5 ? (
          <i className="bi bi-star-fill"></i>
        ) : (
          <i className="bi bi-star"></i>
        )}
      </div>
      <div className="object-list-item-rating-star">
        {rating > 4 && rating < 4.5 ? (
          <i className="bi bi-star-half"></i>
        ) : rating >= 4.5 ? (
          <i className="bi bi-star-fill"></i>
        ) : (
          <i className="bi bi-star"></i>
        )}
      </div>

      {/* <div className="object-list-item-rating-star">{rating >= 1.5 ? <i className="bi bi-star-fill"></i> : <i className="bi bi-star"></i>}</div>
      <div className="object-list-item-rating-star">{rating >= 2.5 ? <i className="bi bi-star-fill"></i> : <i className="bi bi-star"></i>}</div>
      <div className="object-list-item-rating-star">{rating >= 3.5 ? <i className="bi bi-star-fill"></i> : <i className="bi bi-star"></i>}</div>
      <div className="object-list-item-rating-star">{rating >= 4.5 ? <i className="bi bi-star-fill"></i> : <i className="bi bi-star"></i>}</div> */}
    </div>
  );
}

export default Rating;
