import React from "react";
import { Link } from "react-router-dom";
import Image from "../image";
import Placeholder from "react-bootstrap/Placeholder";

function NewsItem(props) {
  const { data, lang, locale, i, loading, homepage } = props;

  function formatDatetime(date) {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  return (
    <>
      {loading && (
        <div
          className={
            homepage
              ? "col-md-4 g-3 decoration"
              : i === 0
              ? "col-md-12 g-3 decoration"
              : "col-md-6 g-3 decoration"
          }
        >
          <div className="news-list-item">
            <div className="news-item-image-frame">
              <Placeholder animation="glow">
                <Placeholder style={{ width: "100%", height: "100%" }} />
              </Placeholder>
            </div>
            <div className="news-item-title pt-3 pb-4 ps-2 pe-2">
              <div className="news-item-date">
                <Placeholder animation="glow">
                  <Placeholder xs={3} size="sm" />
                </Placeholder>
              </div>
              <Placeholder animation="glow">
                <Placeholder xs={10} size="sm" />
              </Placeholder>
              {i !== 0 ? (
                <Placeholder animation="glow">
                  <Placeholder xs={8} size="sm" />
                </Placeholder>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {!loading && data && (
        <Link
          to={"/vesti/" + data.stub}
          className={
            homepage
              ? "col-md-4 g-3 decoration"
              : i === 0
              ? "col-md-12 g-3 decoration"
              : "col-md-6 g-3 decoration"
          }
        >
          <div className="news-list-item">
            <div className="news-item-image-frame">
              <Image
                imageSrc={
                  i === 0
                    ? data.path + "large/" + data.filename
                    : data.path + "medium/" + data.filename
                }
                imageAlt={data["title_" + lang]}
              />
            </div>
            <div className="news-item-title pt-3 pb-4 ps-2 pe-2">
              <div className="news-item-date">
                {formatDatetime(data.published + "000")}
              </div>
              {data["title_" + lang]}
            </div>
          </div>
        </Link>
      )}
    </>
  );
}

export default NewsItem;
