import React from "react";
import Placeholder from "react-bootstrap/Placeholder";

function Distance(props) {
  const { distance, loading } = props;
  return (
    <div className="object-list-item-distance">
      {loading && (
        <Placeholder animation="glow">
          <Placeholder xs={12} size="lg" />
        </Placeholder>
      )}
      {!loading && distance && distance + "km"}
    </div>
  );
}

export default Distance;
