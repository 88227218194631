import React from "react";
import Placeholder from "react-bootstrap/Placeholder";

function NewsContent(props) {
  const { data, lang, locale, loading } = props;

  function formatDatetime(date) {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  return (
    <>
      <div className="row-md mb-3">
        <div className="single-news-date mb-1">
          {loading && (
            <Placeholder animation="glow">
              <Placeholder xs={3} size="sm" />
            </Placeholder>
          )}
          {!loading && data && formatDatetime(data.published + "000")}
        </div>
        {loading && (
          <Placeholder animation="glow">
            <Placeholder xs={10} size="lg" />
          </Placeholder>
        )}
        {!loading && data && (
          <h1 className="single-news-title">{data["title_" + lang]}</h1>
        )}
      </div>
      <div className="row-md mb-3">
        {loading && (
          <Placeholder animation="glow">
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={6} size="sm" />
          </Placeholder>
        )}
        {!loading && data && (
          <div
            className="single-news-text"
            dangerouslySetInnerHTML={{ __html: data["text_" + lang] }}
          ></div>
        )}
      </div>
    </>
  );
}

export default NewsContent;
