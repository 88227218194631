import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";

function ShareModal(props) {
  const { show, onCloseShare, body, subject, media } = props;
  const shareUrl = window.location.href;
  return (
    <Modal
      show={show}
      onHide={onCloseShare}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">
          <i className="bi bi-share-fill icon-primary"></i>
          {"Podeli".translate()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="share-modal-body">
        <EmailShareButton subject={subject} body={body} url={shareUrl}>
          <EmailIcon size={50} borderRadius={10} />
        </EmailShareButton>
        <FacebookShareButton quote={body} url={shareUrl}>
          <FacebookIcon size={50} borderRadius={10} />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={subject}>
          <TwitterIcon size={50} borderRadius={10} />
        </TwitterShareButton>
        <ViberShareButton url={shareUrl} title={subject}>
          <ViberIcon size={50} borderRadius={10} />
        </ViberShareButton>
        <WhatsappShareButton url={shareUrl} title={subject}>
          <WhatsappIcon size={50} borderRadius={10} />
        </WhatsappShareButton>
        <LinkedinShareButton url={shareUrl} title={subject} summary={body}>
          <LinkedinIcon size={50} borderRadius={10} />
        </LinkedinShareButton>
        <PinterestShareButton url={shareUrl} media={media} description={body}>
          <PinterestIcon size={50} borderRadius={10} />
        </PinterestShareButton>
        <TelegramShareButton url={shareUrl} title={subject}>
          <TelegramIcon size={50} borderRadius={10} />
        </TelegramShareButton>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCloseShare}>
          {"Zatvori".translate()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareModal;
