import React, { useState, useEffect } from "react";
import SubHeader from "./subHeader";
import EventsItem from "./eventsItem";

function SidebarLatestEvents(props) {
  const { lang, locale, showSubheader, category } = props;
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        offset: 0,
        count: 5,
        stub: category,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_ROOT + "get_events.php", requestOptions)
      .then((response) => response.json())
      .then((news) => {
        setData(news.events);
        setCategories(news.categories);
        setLoading(false);
      });
  }, [category]);

  return (
    <>
      {showSubheader && (
        <SubHeader
          title={
            !loading && category
              ? categories[category]["name_" + lang]
              : "Najave".translate()
          }
          linkTo={category ? "/najave/" + category : "/najave"}
          loading={loading}
          icon={<i className="bi bi-calendar-date icon-primary"></i>}
        />
      )}
      <div className="row g-0 px-2 pt-1 pb-3 mb-3">
        {loading && (
          <>
            <EventsItem i={1} loading={loading} />
            <EventsItem i={1} loading={loading} />
            <EventsItem i={1} loading={loading} />
            <EventsItem i={1} loading={loading} />
            <EventsItem i={1} loading={loading} />
          </>
        )}
        {!loading &&
          data.map((event, i) => (
            <EventsItem
              categories={categories}
              key={event.event_id}
              lang={lang}
              data={event}
              locale={locale}
              loading={loading}
            />
          ))}
      </div>
    </>
  );
}

export default SidebarLatestEvents;
