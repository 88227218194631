import React, { useState, useEffect } from "react";
import SubHeader from "./subHeader";
import EventsItem from "./eventsItem";
import NewsItem from "./newsItem";

function MostComments(props) {
  const { lang, showSubheader, category, locale, type } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        type: type,
        category: category,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_most_comments.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((event) => {
        setData(event);
        setLoading(false);
      });
  }, [category]);

  return (
    <div className="col-xl-12">
      {showSubheader && (
        <SubHeader
          title={"Najviše komentara".translate()}
          loading={loading}
          icon={<i className="bi bi-chat-left-text-fill icon-primary"></i>}
        />
      )}
      <div className="row g-0 px-2 pt-1 pb-3 mb-3">
        {loading && type === "event" && <EventsItem loading={loading} />}
        {!loading && data && type === "event" && (
          <EventsItem
            categories={data.categories}
            lang={lang}
            data={data.event}
            locale={locale}
            loading={loading}
          />
        )}
        {loading && type === "news" && <NewsItem loading={loading} />}
        {!loading && data && type === "news" && (
          <NewsItem
            loading={loading}
            newsItem={data}
            locale={locale}
            lang={lang}
          />
        )}
      </div>
    </div>
  );
}

export default MostComments;
