import React from "react";

function Image(props) {
  const {
    imageUi,
    imageStatic,
    imageSrc,
    imageAlt,
    imageWidth,
    imageHeight,
    className,
  } = props;
  return (
    <img
      src={
        imageStatic
          ? process.env.REACT_APP_STATIC_RESOURCES + imageSrc
          : imageUi
          ? process.env.REACT_APP_UI_RESOURCES + imageSrc
          : process.env.REACT_APP_RESOURCES + imageSrc
      }
      alt={imageAlt}
      width={imageWidth ? imageWidth : null}
      height={imageHeight ? imageHeight : null}
      className={className ? className : null}
    />
  );
}

export default Image;
