import React, { useState, useEffect } from "react";
import SeoTags from "../components/seo";
import EventsItem from "../components/events/item";
import Header from "../components/header";
import PromoApp from "../components/promoApp";
import SidebarCategories from "../components/events/sidebarCategories";
import { useParams } from "react-router-dom";
import MostPopular from "../components/mostPopular";
import MostLikes from "../components/mostLikes";
import MostComments from "../components/mostComments";
import NotFound from "./notFound";

function Search(props) {
  const [data, setData] = useState(null);
  const [responseCode, setResponseCode] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { lang, locale } = props;
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(10);
  const [loaded, setLoaded] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { category } = useParams();

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        offset: 0,
        count: 10,
        stub: category,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_ROOT + "get_events.php", requestOptions)
      .then((response) => response.json())
      .then((news) => {
        news.events.length === 10 ? setLoaded(false) : setLoaded(true);
        setData(news.events);
        setCategories(news.categories);
        setResponseCode(news.response_code);
        setOffset(10);
        setCount(10);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [category]);

  const loadMore = () => {
    if (!loaded) {
      setLoadingMore(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          city_id: process.env.REACT_APP_CITY_ID,
          offset: offset,
          count: count,
          stub: category,
          origin: "web",
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_ROOT + "get_events.php",
        requestOptions
      )
        .then((response) => response.json())
        .then((news) => {
          let newData = [...data, ...news.events];
          setData(newData);
          if (news.events.length === 10) {
            setOffset(offset + 10);
          } else {
            setLoaded(true);
          }
          setLoadingMore(false);
        });
    }
  };

  return (
    <>
      {!loading && responseCode === "404" ? (
        <NotFound />
      ) : (
        <>
          <SeoTags
            title={
              category && categories
                ? categories[category]["name_sr"]
                : "Sve najave"
            }
            description={
              category && categories
                ? "Pogledajte spisak najava iz kategorije " +
                  categories[category]["name_sr"] +
                  "."
                : "Pogledajte spisak događaja, akcija i promocija u Vrnjačkoj Banji."
            }
            path={"najave/" + (category ? category + "/" : "")}
          />
          <main className="container-fluid p-0">
            <div className="container-fluid p-0 header-wrap">
              <div className="container-md">
                <Header
                  backTo={category ? "/najave" : "/"}
                  firstLine={process.env.REACT_APP_CITY.translate()}
                  secondLine={
                    category && categories
                      ? categories[category]["name_" + lang]
                      : "Sve najave".translate()
                  }
                  loading={loading}
                  icon={
                    <i className="bi bi-calendar-date me-2 icon-primary"></i>
                  }
                />
              </div>
            </div>
            <div className="container-md">
              <div className="row">
                <div className="col-lg-8">
                  <div className="row g-0 px-2 pt-1 pb-3">
                    {loading && (
                      <>
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                        <EventsItem loading={loading} />
                      </>
                    )}
                    {!loading &&
                      data.map((news, i) => (
                        <EventsItem
                          key={news.event_id}
                          categories={categories}
                          lang={lang}
                          data={news}
                          locale={locale}
                          loading={loading}
                        />
                      ))}
                    {!loaded && !loadingMore && (
                      <div className="mt-3 mb-1 text-center">
                        <button onClick={loadMore} className="load-more-button">
                          {"Prikaži više".translate()}
                        </button>
                      </div>
                    )}
                    {loadingMore && (
                      <div className="loading-more mt-3 mb-1">
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sticky-column mt-3 pb-3">
                    <SidebarCategories
                      showSubheader={true}
                      category={category}
                      lang={lang}
                    />
                    <MostPopular
                      lang={lang}
                      showSubheader={true}
                      category={category}
                      locale={locale}
                      type="event"
                    />
                    <MostLikes
                      lang={lang}
                      showSubheader={true}
                      category={category}
                      locale={locale}
                      type="event"
                    />
                    <MostComments
                      lang={lang}
                      showSubheader={true}
                      category={category}
                      locale={locale}
                      type="event"
                    />
                    <PromoApp sidebar={true} />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default Search;
