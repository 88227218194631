import React, { useRef } from "react";
import Image from "../image";
import Placeholder from "react-bootstrap/Placeholder";
function SingleObjectPhoto(props) {
  const { onPhoto, photo, lang, loading } = props;
  const imageRef = useRef(null);
  return (
    <div ref={imageRef} className="col-3 scroll-snap-child">
      {loading && (
        <div className="gallery-photo">
          <Placeholder animation="glow">
            <Placeholder style={{ width: "100%", height: "100%" }} />
          </Placeholder>
        </div>
      )}
      {!loading && photo && (
        <div onClick={() => onPhoto(photo, imageRef)} className="gallery-photo">
          <Image
            imageSrc={photo.path + "small/" + photo.filename}
            imageAlt={photo["text_" + lang]}
          />
        </div>
      )}
    </div>
  );
}

export default SingleObjectPhoto;
