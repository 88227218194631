import React from "react";
import Button from "../button";

function Header(props) {
  const { loading, data, onClickLike, onClickShare, onClickComment } = props;
  return (
    <div className="row px-3">
      <div className="col-md">
        <div className="action-buttons">
          <Button
            key="like"
            title={"Označi da ti se sviđa".translate()}
            clickAction={onClickLike}
            icon={<i className="bi bi-hand-thumbs-up-fill"></i>}
            badgeValue={loading ? "0" : data.likes}
            currentState={loading ? "disabled" : null}
          />

          <Button
            key="share"
            title={"Podeli".translate()}
            clickAction={onClickShare}
            icon={<i className="bi bi-share-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : null}
          />
          <Button
            key="comment"
            title={"Ostavi komentar".translate()}
            clickAction={onClickComment}
            icon={<i className="bi bi-chat-left-text-fill"></i>}
            badgeValue={loading ? "0" : data.comments}
            currentState={loading ? "disabled" : null}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
