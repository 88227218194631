import React, { useState, useEffect } from "react";
import SubHeader from "../../components/subHeader";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import Placeholder from "react-bootstrap/Placeholder";

function SidebarCategories(props) {
  const { lang, group, subgroup, object, map } = props;
  const [subgroupData, setSubgroupData] = useState([]);
  const [loadingSubgroupData, setLoadingSubgroupData] = useState(true);
  const [groupData, setGroupData] = useState([]);
  const [loadingGroupData, setLoadingGroupData] = useState(true);

  function handleToggle(index) {
    let newData = [];
    subgroupData.map((subgroupItem, i) => {
      let currentState = subgroupItem.subgroup_visible;
      i === index
        ? (subgroupItem.subgroup_visible = !currentState)
        : (subgroupItem.subgroup_visible = false);
      newData[i] = subgroupItem;
    });
    setSubgroupData(newData);
  }

  useEffect(() => {
    const requestOptionsGroup = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        stub: "lokacije/" + group,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_group.php",
      requestOptionsGroup
    )
      .then((response) => response.json())
      .then((objects) => {
        let newData = [];
        let stubSubgroup = subgroup;
        let stubObject = object;
        setGroupData(objects);
        setLoadingGroupData(false);
        objects.subgroups.map((subgroupItem, i) => {
          stubSubgroup === subgroupItem.stub
            ? (subgroupItem.subgroup_visible = true)
            : (subgroupItem.subgroup_visible = false);
          if (stubSubgroup === subgroupItem.stub) {
            subgroupItem.objects.map((objectItem) => {
              stubObject === objectItem.stub
                ? (objectItem.item_active = true)
                : (objectItem.item_active = false);
            });
          }
          newData[i] = subgroupItem;
        });
        setSubgroupData(newData);
        setLoadingSubgroupData(false);
      });
  }, [object]);

  return (
    <>
      <SubHeader
        image={groupData.filename}
        title={groupData["name_" + lang]}
        loading={loadingSubgroupData}
      />
      <div className="row g-0 px-2 pt-1 pb-3 mb-3">
        <ul className="sidebar-group-list">
          {loadingSubgroupData && (
            <>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
            </>
          )}
          {!loadingSubgroupData &&
            subgroupData.map((subgroupList, index) => (
              <li
                key={subgroupList.subgroup_id}
                className={
                  subgroupList.subgroup_visible
                    ? "sidebar-group-item-active"
                    : "sidebar-group-item"
                }
              >
                <div
                  onClick={() => handleToggle(index)}
                  className="sidebar-group-item-inner"
                >
                  <div className="sidebar-group-title">
                    {subgroupList["name_" + lang]}
                  </div>
                  <i
                    className={
                      subgroupList.subgroup_visible
                        ? "bi bi-caret-down-fill green"
                        : "bi bi-caret-right-fill green"
                    }
                  ></i>
                </div>
                <Collapse in={subgroupList.subgroup_visible}>
                  <div>
                    <ul className="sidebar-subgroup-list-visible">
                      {subgroupList.objects.map((objectItem) => (
                        <Link
                          key={objectItem.location_id}
                          to={
                            !loadingGroupData &&
                            "/" +
                              groupData.stub +
                              "/" +
                              subgroupList.stub +
                              "/" +
                              objectItem.stub +
                              (map ? "/map" : "")
                          }
                        >
                          <li
                            className={
                              objectItem.item_active
                                ? "sidebar-subgroup-item-active"
                                : "sidebar-subgroup-item"
                            }
                          >
                            <i className="bi bi-caret-right-fill"></i>
                            {objectItem["name_" + lang]}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </Collapse>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export default SidebarCategories;
