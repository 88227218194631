import React from "react";
import { Link } from "react-router-dom";
import Placeholder from "react-bootstrap/Placeholder";

function EventsItem(props) {
  const { data, lang, locale, loading, categories } = props;

  function formatDate(date) {
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  function formatDay(date) {
    let dateTime = new Date(parseInt(date));
    return dateTime.getDate();
  }

  function formatMonth(date) {
    const monthNames = [
      "Jan".translate(),
      "Feb".translate(),
      "Mar".translate(),
      "Apr".translate(),
      "Maj".translate(),
      "Jun".translate(),
      "Jul".translate(),
      "Aug".translate(),
      "Sep".translate(),
      "Okt".translate(),
      "Nov".translate(),
      "Dec".translate(),
    ];
    let dateTime = new Date(parseInt(date));
    return monthNames[dateTime.getMonth()].translate();
  }

  function formatTime(date) {
    let options = { hour: "2-digit", minute: "2-digit", second: undefined };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleTimeString(locale, options);
  }

  function dateIsBigger(date) {
    let biggerDate = Date.now() > parseInt(date);
    return biggerDate;
  }

  function status(dateStart, dateEnd) {
    let dateNow = Date.now();
    return parseInt(dateStart) < dateNow && dateNow < parseInt(dateEnd) ? (
      <div className="event-list-status ongoing">{"U toku...".translate()}</div>
    ) : dateNow > parseInt(dateEnd) ? (
      <div className="event-list-status expired">{"Završeno".translate()}</div>
    ) : (
      ""
    );
  }

  return (
    <>
      {loading && (
        <div className="col-md-12 g-3 decoration">
          <div className="event-list-wrapper">
            <div className="event-list-content">
              <div className="event-list-big-date d-block">
                <div className="event-list-big-day">
                  <Placeholder animation="glow">
                    <Placeholder xs={12} size="sm" />
                  </Placeholder>
                </div>
                <div className="event-list-big-month">
                  <Placeholder animation="glow">
                    <Placeholder xs={12} size="sm" />
                  </Placeholder>
                </div>
              </div>
              <div className="event-list-event-data w-100">
                <div className="event-list-meta d-block">
                  <Placeholder animation="glow">
                    <Placeholder xs={4} size="md" />
                  </Placeholder>
                </div>
                <div className="event-list-title">
                  <Placeholder animation="glow">
                    <Placeholder xs={7} size="md" />
                  </Placeholder>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && data && (
        <Link
          to={"/najave/" + categories[data.category_id].stub + "/" + data.stub}
          className="col-md-12 g-3 decoration"
        >
          <div
            className={
              dateIsBigger(data.finishing_date + "000")
                ? "event-list-wrapper-expired"
                : "event-list-wrapper"
            }
          >
            <div className="event-list-content">
              <div
                className={
                  dateIsBigger(data.finishing_date + "000")
                    ? "event-list-big-date-expired"
                    : "event-list-big-date"
                }
              >
                <div className="event-list-big-day">
                  {formatDay(data.starting_date + "000")}
                </div>
                <div className="event-list-big-month">
                  {formatMonth(data.starting_date + "000")}
                </div>
              </div>
              <div className="event-list-event-data">
                <div className="event-list-meta">
                  <div className="event-list-category">
                    {categories[data.category_id]["name_" + lang]}
                  </div>
                  <div className="ms-2 me-2 event-list-separator">|</div>
                  <div
                    className={
                      dateIsBigger(data.finishing_date + "000")
                        ? "event-list-date-expired"
                        : "event-list-date"
                    }
                  >
                    <i className="bi bi-calendar3 me-1 icon-primary"></i>
                    {parseInt(data.starting_date) + 86400 <=
                    parseInt(data.finishing_date)
                      ? formatDate(data.starting_date + "000") +
                        " - " +
                        formatDate(data.finishing_date + "000")
                      : formatDate(data.starting_date + "000")}
                  </div>

                  {parseInt(data.starting_date) + 86400 >
                    parseInt(data.finishing_date) && (
                    <div
                      className={
                        dateIsBigger(data.finishing_date + "000")
                          ? "event-list-date-expired ms-2"
                          : "event-list-date ms-2"
                      }
                    >
                      <i className="bi bi-clock me-1 icon-primary"></i>
                      {formatTime(data.starting_date + "000")}
                    </div>
                  )}
                </div>
                <div className="event-list-title">{data["title_" + lang]}</div>
              </div>
            </div>
            {status(data.starting_date + "000", data.finishing_date + "000")}
          </div>
        </Link>
      )}
    </>
  );
}

export default EventsItem;
