import React, { useState, useEffect } from "react";
import SeoTags from "../components/seo";
import { useParams } from "react-router-dom";
import MainPhoto from "../components/eventsSingle/mainPhoto";
import Header from "../components/header";
import Buttons from "../components/eventsSingle/buttons";
import MainContent from "../components/eventsSingle/content";
import Comment from "../components/comment";
import ShareModal from "../components/shareModal";
import CommentModal from "../components/commentModal";
import PromoApp from "../components/promoApp";
import SubHeader from "../components/subHeader";
import SidebarLatestEvents from "../components/sidebarLatestEvents";
import ConfirmationDialog from "../components/confirmationDialog";
import NotFound from "./notFound";

function EventsSingle(props) {
  const { eventsSingle } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { lang, locale, addToast } = props;
  const [showShare, setShowShare] = useState(false);
  const handleCloseShare = () => setShowShare(false);
  const [showComment, setShowComment] = useState(false);
  const [commentError, setCommentError] = useState(null);
  const [commentLoading, setCommentLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { category } = useParams();

  const updateLikes = (newLikes) => {
    let newData = { ...data };
    newData.likes = newLikes;
    setData(newData);
  };

  const handleLike = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        event_id: data.event_id,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "add_events_like.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.response_code === "200") {
          addToast("Uspešno", data.response, "success");
          updateLikes(data.new_likes);
          setShowConfirm(false);
        } else {
          addToast("Greška!", data.response, "error");
          setShowConfirm(false);
        }
      });
  };

  const handleSubmitComment = (name, email, comment, privacy) => {
    setCommentError(null);
    setCommentLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        event_id: data.event_id,
        comment_name: name,
        comment_email: email,
        comment_text: comment,
        privacy_policy: privacy,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "add_events_comment.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.response_code === "200") {
          setCommentLoading(false);
          setTimeout(function () {
            handleCloseComment();
            addToast("Uspešno", data.response, "success");
          }, 300);
        } else {
          setCommentLoading(false);
          setCommentError(data.response.translate());
        }
      });
  };

  const handleCloseComment = () => {
    setShowComment(false);
    setCommentError(null);
  };

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        stub: eventsSingle,
        origin: "web",
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_events_single.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((news) => {
        setData(news);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [eventsSingle]);

  return (
    <>
      {!loading && data.response_code === "404" ? (
        <NotFound />
      ) : (
        <>
          <SeoTags
            title={!loading && data.title_sr + " - " + data.meta.name_sr}
            description={
              !loading &&
              data.text_sr
                .replace(/(<([^>]+)>)/gi, "")
                .replace(/(\r\n|\n|\r)/gm, "")
                .split(" ")
                .slice(0, 15)
                .join(" ") + "..."
            }
            path={"najave/" + category + "/" + (!loading && data.stub) + "/"}
            type="article"
            image={
              process.env.REACT_APP_RESOURCES + (!loading && data.filename)
            }
          />
          <main className="container-fluid p-0">
            <div className="container-fluid p-0 header-wrap">
              <div className="container-md">
                <Header
                  backTo="/najave"
                  firstLine={"Najave".translate()}
                  secondLine={data && data.meta["name_" + lang]}
                  loading={loading}
                  icon={
                    <i className="bi bi-calendar-date me-2 icon-primary"></i>
                  }
                />
              </div>
            </div>
            <div className="container-md">
              <div className="row">
                <div className="col-lg-8">
                  <Buttons
                    onClickLike={() => {
                      setShowConfirm(true);
                    }}
                    onClickShare={() => {
                      setShowShare(true);
                    }}
                    onClickComment={() => {
                      setShowComment(true);
                    }}
                    loading={loading}
                    data={!loading && data}
                  />
                  <div className="row g-0 p-3">
                    <div className="col-md">
                      <MainPhoto
                        lang={lang}
                        data={!loading && data}
                        locale={locale}
                        loading={loading}
                      />
                      <MainContent
                        data={data}
                        lang={lang}
                        loading={loading}
                        locale={locale}
                      />
                      {!loading && data && (
                        <>
                          {data && (
                            <SubHeader
                              className="mb-3"
                              title={
                                "Komentari".translate() +
                                " (" +
                                data.comments +
                                ")"
                              }
                              h2={true}
                              loading={loading}
                            />
                          )}
                          <div className="row ps-3 pe-3">
                            {data && data.comments !== "0" ? (
                              data.comments_list.map((comment, i, arr) => (
                                <Comment
                                  key={comment.id}
                                  commentName={comment.comment_name}
                                  commentText={comment.comment_text}
                                  commentDate={comment.comment_date}
                                  i={i}
                                  arr={arr}
                                  locale={locale}
                                />
                              ))
                            ) : (
                              <div className="col-md-12 p-3">
                                <div className="comment-text">
                                  {"Trenutno nema komentara.".translate()}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sticky-column mt-3 pb-3">
                    <SidebarLatestEvents
                      category={category}
                      showSubheader={true}
                      lang={lang}
                      locale={locale}
                    />
                    <PromoApp sidebar={true} />
                  </div>
                </div>
              </div>
              <ShareModal
                show={showShare}
                onCloseShare={handleCloseShare}
                body={data && data["text_" + lang]}
                subject={data && data["title_" + lang]}
                media={data && process.env.REACT_APP_RESOURCES + data.filename}
              />
              <CommentModal
                lang={lang}
                show={showComment}
                onSubmitComment={handleSubmitComment}
                onCloseComment={handleCloseComment}
                commentLoading={commentLoading}
                commentError={commentError}
              />
              <ConfirmationDialog
                icon={
                  <i className="bi bi-hand-thumbs-up-fill icon-primary"></i>
                }
                title={"Sviđanje".translate()}
                body={"Da li želite da označite da vam se sviđa ovaj događaj?".translate()}
                confirmButton={"Da".translate()}
                closeButton={"Ne".translate()}
                show={showConfirm}
                onCloseDialog={() => {
                  setShowConfirm(false);
                }}
                onConfirmDialog={handleLike}
              />
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default EventsSingle;
