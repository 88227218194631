import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ConfirmationDialog(props) {
  const {
    show,
    onCloseDialog,
    onConfirmDialog,
    icon,
    title,
    body,
    confirmButton,
    closeButton,
  } = props;

  return (
    <Modal
      show={show}
      onHide={onCloseDialog}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">
          {icon}
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onConfirmDialog}>
          {confirmButton}
        </Button>
        <Button variant="danger" onClick={onCloseDialog}>
          {closeButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationDialog;
