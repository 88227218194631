import React from "react";
function CookiePolicySerbian() {
  return (
    <>
      <h1>Politika o kolačićima</h1>
      <p>Verzija: 1, Važi od: 01.01.2023.</p>
      <p>
        Kolačići su mali tekstualni fajlovi koji se čuvaju na Vašem računaru ili
        mobilnom uređaju obezbeđujući efikasnije funkcionisanje web sajtova i
        stranica. Kolačići omogućavaju našem sajtu da Vas prepozna i zapamti
        važne informacije kako bi Vam korišćenje sajta bilo udobnije.
      </p>
      <h2>Kako treća lica koriste kolačiće na našem sajtu?</h2>
      <p>
        Kako bi obezbedilo određene usluge na svom web sajtu, preduzeće Inndex
        Media d.o.o. Beograd može sarađivati sa trećim licima. Drugi oglašivači
        i ostale organizacije mogu koristiti svoje kolačiće da bi prikupili
        informacije o Vašim aktivnostima na našem web sajtu. Ove informacije
        mogu koristiti trećem licu da prikaže oglase za koje veruje da će Vama
        biti od značaja na osnovu sadržaja koji ste gledali. Predmetni
        oglašivači mogu koristiti ove informacije u cilju utvrđivanja stepena
        efektivnosti svojih oglasa. Kolačiće trećeg lica mi ne kontrolišemo, da
        biste ih onemogućili ili izbrisali molimo Vas da više informacija i
        instrukcije za onemogućavanje potražite na web stranici relevantnog
        trećeg lica.
      </p>
      <h2>Koje kolačiće koristimo?</h2>
      <p>
        Preduzeće Inndex Media d.o.o. Beograd na ovom web sajtu koristi jedino
        kolačiće funkcionalnosti. Kolačići funkcionalnosti nam pomažu da
        unapredimo Vaše korisničko iskustvo prilikom posete našem sajtu.
      </p>
      <h2>Izmene ove Politike o kolačićima</h2>
      <p>
        Politika o kolačićima se ažurira s vremena na vreme. Zbog toga,
        savetujemo vas da periodično pogledate ovu stranicu zbog mogućih izmena.
        Obavestićemo vas o izmenama postavljanjem novog dokumenta na ovu stranu,
        i zahtevom da ponovite prihvatanje nove verzije Politike o kolačićima.
        Izmene u dokumentu važe od momenta postavljanja na ovu stranu.
      </p>
      <h2>Kontaktirajte nas</h2>
      <p>
        Ukoliko imate bilo kakva pitanja ili sugestije u vezi naše Politike o
        kolačićima, ne oklevajte i kontaktirajte nas na office@inmedia.rs
      </p>
    </>
  );
}

export default CookiePolicySerbian;
