import React from "react";
import Image from "../components/image";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";

function Header(props) {
  const {
    backTo,
    firstLine,
    secondLine,
    loading,
    h1,
    linkTo,
    buttonTitle,
    icon,
  } = props;

  return (
    <div className="row g-0 px-3 py-4">
      <div className="col-md">
        <div className="header-inner">
          <div className="header-inner-left">
            <div className="header-inner-left-inner">
              {icon && <div className="header-inner-left-icon">{icon}</div>}
              <div className="header-inner-left-text">
                <div className="header-inner-left-prefix">
                  {loading && (
                    <Placeholder animation="glow">
                      <Placeholder xs={2} size="sm" />
                    </Placeholder>
                  )}
                  {!loading && firstLine && firstLine}
                </div>
                {h1 && (
                  <h1 className="header-inner-left-title mb-0">
                    {loading && (
                      <Placeholder animation="glow">
                        <Placeholder xs={4} size="sm" />
                      </Placeholder>
                    )}
                    {!loading && secondLine && secondLine}
                  </h1>
                )}
                {!h1 && (
                  <div className="header-inner-left-title mb-0">
                    {loading && (
                      <Placeholder animation="glow">
                        <Placeholder xs={4} size="sm" />
                      </Placeholder>
                    )}
                    {!loading && secondLine && secondLine}
                  </div>
                )}
              </div>
            </div>
          </div>
          {backTo && (
            <div className="header-inner-right">
              <Link to={backTo} title={"Nazad".translate()}>
                <div className="back-button">
                  <Image
                    imageUi={true}
                    imageSrc="icon-back.svg"
                    imageAlt={"Nazad".translate()}
                  />
                </div>
              </Link>
            </div>
          )}
          {!backTo && linkTo && buttonTitle && (
            <div className="header-inner-right">
              <Link to={linkTo}>
                <button className="read-more-button">
                  {buttonTitle}
                  <i className="bi bi-box-arrow-in-right"></i>
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
