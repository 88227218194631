import React, { useState, useEffect } from "react";
import SubHeader from "../subHeader";
import LocationItem from "./locationItem";

function SidebarLatest(props) {
  const { lang, showSubheader } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_ROOT + "get_latest.php", requestOptions)
      .then((response) => response.json())
      .then((location) => {
        setData(location);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {showSubheader && (
        <SubHeader
          title={"Najnovije lokacije".translate()}
          loading={loading}
          icon={<i className="bi bi-patch-check icon-primary"></i>}
        />
      )}
      <div className="row g-0 px-2 pt-1 pb-3 mb-3">
        {loading && (
          <>
            <LocationItem loading={loading} />
            <LocationItem loading={loading} />
            <LocationItem loading={loading} />
          </>
        )}
        {!loading &&
          data &&
          data.map((singleLocation) => (
            <LocationItem
              key={"sideLocation" + singleLocation.location_id}
              loading={loading}
              location={singleLocation}
              lang={lang}
            />
          ))}
      </div>
    </>
  );
}

export default SidebarLatest;
