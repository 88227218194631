import React from "react";
function Comment(props) {
  const { commentName, commentText, commentDate, i, arr, locale } = props;

  function formatDatetime(date) {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  return (
    <div
      className={
        i + 1 === arr.length ? "col-md-12 p-3" : "col-md-12 p-3 border-bottom"
      }
    >
      <div className="comment-content">
        <div className="comment-header mb-2">
          <div className="user-badge">
            <i className="bi bi-person-fill"></i>
          </div>
          <div className="comment-name">{commentName}</div>
          <div className="comment-separator"></div>
          <div className="comment-date">
            {formatDatetime(commentDate + "000")}
          </div>
        </div>
        <div className="comment-text">{commentText}</div>
      </div>
    </div>
  );
}

export default Comment;
