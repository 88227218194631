import React, { useState, useEffect } from "react";
import SubHeader from "../subHeader";
import LocationItem from "./locationItem";

function MostPopular(props) {
  const { lang, showSubheader, category } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        type: "location",
        category: category,
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_most_popular.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((location) => {
        setData(location);
        setLoading(false);
      });
  }, []);

  return (
    <div className="col-xl-12">
      {showSubheader && (
        <SubHeader
          title={"Najviše pregleda".translate()}
          loading={loading}
          icon={<i className="bi bi-star-fill icon-primary"></i>}
        />
      )}
      <div className="row g-0 px-2 pt-1 pb-3 mb-3">
        {loading && <LocationItem loading={loading} />}
        {!loading && data && (
          <LocationItem
            loading={loading}
            location={data}
            lang={lang}
            imageSize="m"
          />
        )}
      </div>
    </div>
  );
}

export default MostPopular;
