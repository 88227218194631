import React from "react";
import Button from "../button";

function Buttons(props) {
  const {
    onSortByName,
    nameState,
    onSortByRating,
    ratingState,
    onSortByDistance,
    distanceState,
    loading,
  } = props;
  return (
    <div className="row px-3">
      <div className="col-md">
        <div className="action-buttons">
          <Button
            key="distance"
            title={"Sortiraj prema udaljenosti".translate()}
            clickAction={onSortByDistance}
            icon={<i className="bi bi-geo-alt-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : distanceState}
          />
          <Button
            key="rating"
            title={"Sortiraj prema oceni".translate()}
            clickAction={onSortByRating}
            icon={<i className="bi bi-star-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : ratingState}
          />
          <Button
            key="name"
            title={"Sortiraj prema nazivu".translate()}
            clickAction={onSortByName}
            icon={<i className="bi bi-filter-circle-fill"></i>}
            badgeValue="0"
            currentState={loading ? "disabled" : nameState}
          />
        </div>
      </div>
    </div>
  );
}

export default Buttons;
