import React from "react";
import SubHeader from "../subHeader";
import SearchResultsLocation from "./searchResultsLocation";
import SearchResultsEvent from "./searchResultsEvent";
import SearchResultsNews from "./searchResultsNews";
function SearchResultsSegment(props) {
  const { loading, data, title, icon, type, lang, locale } = props;
  return (
    <>
      <SubHeader title={title} loading={loading} icon={icon} />
      <div className="row pt-3 mb-3">
        {type === "locations" &&
          (!loading && data && data.length > 0 ? (
            data.map((singleLocation) => (
              <SearchResultsLocation
                key={"location" + singleLocation.location_id}
                lang={lang}
                loading={loading}
                location={singleLocation}
              />
            ))
          ) : (
            <div>{"Nema pogodaka".translate()}</div>
          ))}
        {type === "events" &&
          (!loading && data && data.length > 0 ? (
            data.map((singleEvent) => (
              <SearchResultsEvent
                key={"event" + singleEvent.event_id}
                data={singleEvent}
                lang={lang}
                locale={locale}
                loading={loading}
              />
            ))
          ) : (
            <div>{"Nema pogodaka".translate()}</div>
          ))}
        {type === "news" &&
          (!loading && data && data.length > 0 ? (
            data.map((singleNews) => (
              <SearchResultsNews
                key={"news" + singleNews.news_id}
                loading={loading}
                newsItem={singleNews}
                locale={locale}
                lang={lang}
              />
            ))
          ) : (
            <div>{"Nema pogodaka".translate()}</div>
          ))}
      </div>
    </>
  );
}

export default SearchResultsSegment;
