import React from "react";
function CookiePolicyEnglish() {
  return (
    <>
      <h1>Cookie Policy</h1>
      <p>Version: 1, Valid from: 01.01.2023</p>
      <p>
        Cookies are small text files that are stored on your computer or mobile
        device, ensuring more efficient functioning of websites and pages.
        Cookies enable our site to recognize you and remember important
        information to make your use of the site more comfortable.
      </p>
      <h2>How do third parties use cookies on our site?</h2>
      <p>
        In order to provide certain services on this website, the company Inndex
        Media d.o.o. Belgrade can cooperate with third parties. Other
        advertisers and other organizations may use their cookies to collect
        information about your activities on our website. This information may
        be used by a third party to display advertisements that it believes will
        be of interest to you based on the content you have viewed. The
        advertisers in question may use this information to determine the
        effectiveness of their ads. We do not control third-party cookies, to
        disable or delete them, please refer to the relevant third-party website
        for more information and instructions on how to disable them.
      </p>
      <h2>What cookies do we use?</h2>
      <p>
        The company Inndex Media d.o.o. Belgrade uses only functionality cookies
        on this website. Functionality cookies help us improve your user
        experience when visiting our site.
      </p>
      <h2>Changes to this Cookie Policy</h2>
      <p>
        The cookie policy is updated from time to time. Therefore, we advise you
        to periodically check this page for possible changes. We will notify you
        of the changes by posting a new document on this page, and asking you to
        repeat the acceptance of the new version of the Cookie Policy. Changes
        in the document are valid from the moment they are published on this
        page.
      </p>
      <h2>Contact us</h2>
      <p>
        If you have any questions or suggestions regarding our Cookie Policy, do
        not hesitate to contact us at office@inmedia.rs
      </p>
    </>
  );
}

export default CookiePolicyEnglish;
