import React, { useState, useRef, useCallback } from "react";
import {
  useMatch,
  useResolvedPath,
  NavLink,
  useNavigate,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";

function NavBar(props) {
  const { lang, onChangeLang, languages } = props;
  const [expanded, setExpanded] = useState(false);
  const [searchGroup, setSearchGroup] = useState(0);
  const searchTerm = useRef();
  const navigate = useNavigate();
  const [searchVisible, setSearchVisible] = useState(false);

  const Search = () => {
    setExpanded(false);
    navigate(
      "/rezultati-pretrage?search_term=" +
        searchTerm.current.value +
        "&search_group=" +
        searchGroup
    );
  };

  function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: false });
    return (
      <li className="nav-item">
        <NavLink
          className={isActive ? "nav-link active" : "nav-link"}
          to={to}
          {...props}
        >
          {children}
        </NavLink>
      </li>
    );
  }

  return (
    <Navbar
      expand="lg"
      bg="dark"
      variant="dark"
      // className="mb-3"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src={process.env.REACT_APP_MAIN_LOGO}
            className="d-inline-block align-top"
            alt={process.env.REACT_APP_NAME.translate() + " logo"}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Offcanvas
          id="navbar"
          placement="top"
          onHide={() => setExpanded(false)}
          className="navbar-dark bg-dark"
        >
          <Offcanvas.Header closeButton>
            <Navbar.Brand href="/">
              <img
                src={process.env.REACT_APP_MAIN_LOGO}
                className="d-inline-block align-top"
                alt={process.env.REACT_APP_NAME.translate() + " logo"}
              />
            </Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="mob-adjust">
              <Nav className="me-auto mt-auto mb-auto">
                <Nav.Link
                  as={CustomLink}
                  to="/lokacije"
                  onClick={() => setExpanded(false)}
                >
                  <i className="bi bi-geo-alt me-2 icon-primary"></i>
                  {"Lokacije".translate()}
                </Nav.Link>
                <Nav.Link
                  as={CustomLink}
                  to="/najave"
                  onClick={() => setExpanded(false)}
                >
                  <i className="bi bi-calendar-date me-2 icon-primary"></i>
                  {"Najave".translate()}
                </Nav.Link>
                <Nav.Link
                  as={CustomLink}
                  to="/vesti"
                  onClick={() => setExpanded(false)}
                >
                  <i className="bi bi-newspaper me-2 icon-primary"></i>
                  {"Vesti".translate()}
                </Nav.Link>
              </Nav>
              <Nav className="addon-buttons">
                <InputGroup
                  className={
                    searchVisible ? "search-wrap-active" : "search-wrap"
                  }
                >
                  <DropdownButton
                    title={
                      searchGroup === 0
                        ? "Sve".translate()
                        : searchGroup === 1
                        ? "Lokacije".translate()
                        : searchGroup === 2
                        ? "Najave".translate()
                        : searchGroup === 3
                        ? "Vesti".translate()
                        : ""
                    }
                    align="start"
                  >
                    <Dropdown.Item
                      onClick={() => {
                        setSearchGroup(0);
                      }}
                    >
                      {"Sve".translate()}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        setSearchGroup(1);
                      }}
                    >
                      {"Lokacije".translate()}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSearchGroup(2);
                      }}
                    >
                      {"Najave".translate()}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setSearchGroup(3);
                      }}
                    >
                      {"Vesti".translate()}
                    </Dropdown.Item>
                  </DropdownButton>
                  <Form.Control
                    ref={searchTerm}
                    className="header-input"
                    placeholder={"Pretraži...".translate()}
                    onKeyDown={(e) => {
                      e.key === "Enter" && searchTerm.current.value && Search();
                    }}
                  />
                  <Button
                    onClick={() => {
                      searchTerm.current.value && Search();
                    }}
                    title={"Pretraži".translate()}
                  >
                    <i className="bi bi-search"></i>
                  </Button>
                </InputGroup>
                <button
                  onClick={() => {
                    searchTerm.current.value = "";
                    setSearchVisible(!searchVisible);
                  }}
                  title={"Pretraži".translate()}
                  className="open-search"
                >
                  {searchVisible ? (
                    <i className="bi bi-x-circle"></i>
                  ) : (
                    <i className="bi bi-search"></i>
                  )}
                </button>
                <DropdownButton
                  title={lang}
                  align="end"
                  className="lang-select"
                >
                  {languages.map((singleLanguage, i) => (
                    <Dropdown.Item
                      key={"language" + i}
                      onClick={() => onChangeLang(singleLanguage.shortName)}
                    >
                      {singleLanguage.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Nav>
            </div>

            <div className="offcanvas-footer">
              {process.env.REACT_APP_IPHONE_LINK !== "#" && (
                <div>
                  <a
                    href={process.env.REACT_APP_IPHONE_LINK}
                    target="_blank"
                    title={"Preuzmi aplikaciju na App Store".translate()}
                  >
                    <i className="bi bi-apple"></i>
                  </a>
                </div>
              )}
              {process.env.REACT_APP_ANDROID_LINK !== "#" && (
                <div>
                  <a
                    href={process.env.REACT_APP_ANDROID_LINK}
                    target="_blank"
                    title={"Preuzmi aplikaciju na Google Play".translate()}
                  >
                    <i className="bi bi-google-play"></i>
                  </a>
                </div>
              )}
              {process.env.REACT_APP_FACEBOOK_LINK !== "#" && (
                <div>
                  <a
                    href={process.env.REACT_APP_FACEBOOK_LINK}
                    target="_blank"
                    title={"Poseti našu Facebook stranicu".translate()}
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </div>
              )}
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default NavBar;
