import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
function SingleObjectText(props) {
  const { text, lang, loading } = props;
  return (
    <>
      {loading && (
        <div className="col-md">
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={8} size="sm" />
          </Placeholder>
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={10} size="sm" />
          </Placeholder>
        </div>
      )}
      {!loading && text && (
        <div className="col-md">
          <div className="text">{text["text_" + lang]}</div>
        </div>
      )}
    </>
  );
}

export default SingleObjectText;
